<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Logs hệ thống</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Logs hệ thống</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <loading-vue v-if="loading" />
      <div class="row">
        <div class="col-md-12">
          <pre>
            <code>
              {{ logs }}
            </code>
          </pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import logsApi from "@/api/logs";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery, formatNumber } from "@/helpers/index";

export default {
  name: "Logs",
  components: {
    LoadingVue,
  },
  data() {
    return {
      logs: [],
    };
  },
  mounted() {
    this.getLogsList();
  },
  methods: {
    async getLogsList() {
      // const params = {
      // };
      // const queries = buildParamQuery(params);
      const queries = "";
      this.loading = true;
      const response = await logsApi.getStorageLogs(queries);
      this.loading = false;
      if (response) {
        if (response.success) {
          this.logs = response.data;
        } else {
          this.$toastr.error(response.message);
        }
      } else {
        this.$toastr.error("Tải không thành công !");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
